import { ActionButton } from '@components/ui/button/ActionButton'
import { TDAny } from '@components/ui/table/TDAny'
import { TDText } from '@components/ui/table/TDText'
import { TRPar } from '@components/ui/table/TRPar'
import { TableBody } from '@components/ui/table/TableBody'
import { BoldText } from '@components/ui/text/BoldText'
import { TypeSells } from '@models/sells/helpersSells'
import { setEditBill } from '@redux/features/billSlice/billSlice'
import { useAppDispatch } from '@redux/store/hooks'
import { useCallback, useEffect, useState } from 'react'
import { orderBy } from 'lodash';
import useFilterTable from '@lib/hooks/ui/useFilterTable'
import { TableHead } from '@components/ui/table/TableHead'
import { TablePag } from '@components/ui/table/TablePag'
import { Sell, SimpleSell } from '@models/sells/sell'
import useCheckRole from '@lib/hooks/auth/useCheckRole'
const tableHeads = ['Venta', 'Metodo', 'Cliente', 'Empleado', 'Precio', 'Editar precio', 'Eliminar', 'Editar método']
interface Props {
  arr: any[],
  type: TypeSells
}
// order table by name
/* 
  change edits and deletes to new schema, always will habe simple sell
*/
export const TableBill = ({ arr, type }: Props) => {
  const {onlyOwners, noticeWorker} = useCheckRole()
  const [dataArr, setDataArr] = useState<any[]>([])
  const dispatch = useAppDispatch();
  const [heads, setheads] = useState<string[]>([])
  useEffect(() => {
    setheads(tableHeads)
  }, [])

  const title = useCallback(() => {
    switch (type) {
      case 'PRODS':
        return 'Productos'
      case 'BOOKS':
        return 'Reservas'
      case 'TICKS':
        return 'Tickets'
      case 'BONUS':
        return 'Bonos'
      default:
        return '';
    }
  }, [type])
  const editPrice = (item: SimpleSell) => {
    if (onlyOwners()) {
      const toDis = item as any;
      delete toDis.dateTime
      dispatch(setEditBill({ item: toDis, docId: item.parentId, type: type, actions: 'AMOUNT' }))
    } else {
      noticeWorker()
    }
  }
  const editMethod = (item: SimpleSell) => {
    if (onlyOwners()) {
      const toDis = item as any;
      delete toDis.dateTime
      dispatch(setEditBill({ item: toDis, docId: item.parentId, type: type, actions: 'METHOD' }))
    } else {
      noticeWorker()
    }
  }

  const delItem = async (item: SimpleSell) => {
    if (onlyOwners()) {
      const toDis = item as any;
      delete toDis.dateTime;
      dispatch(setEditBill({ item: toDis, docId: item.parentId, type: type, actions: 'DELETE' }))
    } else {
      noticeWorker()
    }
  }

  useEffect(() => {
    if (type === 'BOOKS') {
      const o = orderBy(arr, o => o.userName)
      setDataArr(o)
    } else {
      setDataArr(arr)
    }
  }, [arr, type])
  const { dataTable, setDebounceQuery, totalResults, currentPage, resultsPerPage, handleChangePage } = useFilterTable(dataArr, 200);
  const passSeachterm = useCallback(
    (str: string) => {
      setDebounceQuery(str)
    },
    [setDebounceQuery],
  )

  return (
    <div>
      {
        arr.length > 0 ?
          <div className='p-5'>
            <div className='separator bg-est mb-4' style={{ height: 2 }}></div>
            <TableBody heads={heads} options={
              <div className='d-flex align-items-center justify-content-between'>
                <BoldText classNames='fs-3 mb-0' text={title()} />
                <div className='d-flex justify-content-start align-items-center'>
                  <TableHead passSearch={passSeachterm} />
                </div>
              </div>
            }>
              {
                (dataTable).map((value) => (
                  <TRPar key={value.docId ? value.docId : value.uidFind}>
                    <TDAny classNames='w-25px'>
                      <BoldText text={value.name} />
                    </TDAny>
                    <TDText text={value.paymentMethod === 'Mixto' ? 'Tarjeta / Efectivo' : value.paymentMethod} />
                    <TDText text={value.userName} />
                    <TDText text={value.workerName} />
                    <TDText text={`${value.amount.toFixed(2)} €`} />
                    {
                      value.paymentMethod === 'Ticket' ?
                        <TDText text={'Pagado con ticket'} />
                        :
                        <TDAny classNames='w-25px'>
                          <ActionButton text='Editar' action={() => { editPrice(value) }} />
                        </TDAny>
                    }
                    <TDAny classNames='w-25px'>
                      <ActionButton text='Eliminar' action={() => { delItem(value) }} />
                    </TDAny>
                    {
                      value.paymentMethod === 'Mixto' ?
                        <TDText text={`Vendido: ${value.orderDate}`} />
                        :
                        (
                          value.paymentMethod === 'Ticket' ?
                            <TDText text={'Pagado con ticket'} />
                            :
                            <TDAny classNames='w-25px'>
                              <ActionButton text='Editar' action={() => { editMethod(value) }} />
                            </TDAny>
                        )
                    }
                  </TRPar>
                ))
              }
              <TablePag className={'pagination-bar'} totalResults={totalResults} currentPage={currentPage} resultsPerPage={resultsPerPage} onChange={handleChangePage} />
            </TableBody>
          </div>
          : null
      }
    </div>
  )
}
