import { ActionButton } from '@components/ui/button/ActionButton'
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ConnectComplete = () => {
    const center = useAppSelector(selectCenter)
    const [isCompleted, setisCompleted] = useState(true);
    const nav = useNavigate();
    useEffect(() => {
        if (center) {
            setisCompleted(center.bankIsOk)
        }
    }, [center])
    
    const goComplete = () => {
        nav('/configuracion/config-app')
    }
    
    return (
        <>
            {
                !isCompleted ?
                <ActionButton classNames='me-3' isDanger={true} text='Sin transferencias online' action={goComplete} />
                : null
            }
        </>
    )
}
