import { db, fireAuth } from '@lib/firebase'
import { Centers } from '@models/centers/center'
import { useAuth } from 'app/modules/auth'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useCallback, } from 'react'
import { useNavigate } from "react-router-dom";
import { ActionButton } from '@components/ui/button/ActionButton'
import { signOut } from 'firebase/auth'
import { SearchQuery } from '@components/common/search/SearchQuery'
export const ChoiceSuperAd = () => {
  const nav = useNavigate()
  const {currentUser} = useAuth();
  const signOutEst = async () => {
    await signOut(fireAuth)
  }
  const getCenter = useCallback(
    async (c: Centers) => {
      await updateDoc(doc(db, `users/${currentUser?.uid}`), {
        centerWorkerName: c.centerName,
        canary: c.canary
      })
      nav('/dashboard')
    },
    [currentUser, nav],
  )
  

  return (
    <>   
    <div className='d-flex flex-column'>
        <p className="mb-2 mt-2 text-start">Elige centro</p>
        <SearchQuery 
        keyText={'displayName'}
        suggSel={getCenter} 
        colName={'centers'} 
        keySearch={'searchString'} 
        keyId={'centerName'} 
        />

    </div>
        <div className='d-flex flex-column '>
            <p className="mb-2 mt-10 text-end">¿Quieres cerrar sesión?</p>
            <div className='mt-2 d-flex justify-content-end'>
              <ActionButton text={'Cerrar sesión'} action={signOutEst} />
            </div>
        </div>
    </>
  )
}

