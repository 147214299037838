import { db } from '@lib/firebase'
import useComponentVisible from '@lib/hooks/ui/useComponentVisible'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { useCallback, useState } from 'react'
type Props = {
    suggSel: (item: any) => void,
    colName: string;
    keySearch: string;
    keyId: string;
    keyText?: string
}
export const SearchQuery = ({ suggSel, colName, keySearch, keyId, keyText }: Props) => {
    const { setIsComponentVisible, isComponentVisible, ref, handlerRef } = useComponentVisible(false)
    const [timer, setTimer] = useState<any>(null);
    const [searchTerm, setSearch] = useState<string>('');
    const [filtered, setfiltered] = useState<any[]>([]);
    const trFun = useCallback((str: string) => {
        return str.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }, []);
    const search = useCallback(
        async (text: string) => {
            const trStr = trFun(text);
            const arr = trStr.split(' ');
            const qArrayContains = query(collection(db, colName), where(keySearch, 'array-contains', trStr));
            const qArrayAny = query(collection(db, colName),
                where(keySearch, 'array-contains-any', arr));
            const arrayContains = ((await getDocs(qArrayContains)).docs).map((v) => v.data()) as any[];
            const arrayAny = ((await getDocs(qArrayAny)).docs).map((v) => v.data()) as any[];
            const both = arrayContains.concat(arrayAny);
            const final = Array.from(
                new Set(both.map(v => v[keyId]))
            ).map(uniqueKey =>
                both.find(v => v[keyId] === uniqueKey)
            );
            setfiltered(final)
        }, [trFun, colName, keyId, keySearch])

    const inputChanged = (e: any) => {
        setSearch(e.target.value)
        if (timer) {
            clearTimeout(timer)
        }

        const newTimer = setTimeout(() => {
            if (searchTerm.length > 1) {
                setIsComponentVisible(true)
                search(searchTerm)
            } else {
                setIsComponentVisible(false)
                setfiltered([])
            }
        }, 25)

        setTimer(newTimer)
    }

    const suggSelected = useCallback(
        (item: any) => {
            setIsComponentVisible(false);
            suggSel(item);
            setSearch('')
        },
        [suggSel, setIsComponentVisible],
    )
    return (
        <div className={`d-flex position-relative flex-column`} ref={handlerRef}>
            <input
                autoComplete='off'
                type='text'
                className={`form-control form-control-lg form-control-solid mb-3 mb-lg-0 `}
                placeholder={'Buscar...'}
                onChange={inputChanged}
                value={searchTerm}
            />
            <div
                ref={ref}
                className={` ${!isComponentVisible ? 'd-none' : ''} over-scroll card position-absolute z-index-1 w-100 sugg-app `}>
                {filtered.length > 0 ?
                    filtered.map((value) => (
                        <div className='p-2 text-hover-primary text-left is-pointer ' key={value[keyId]} onClick={() => suggSelected(value)}>
                            {
                                keyText ?
                                <p>{`${value[keyText]}`}</p>
                                :
                                <p>{`${value.displayName} ${value.phone}`}</p>
                            }
                        </div>
                    ))
                    : <p className='text-center mt-5'>Sin resultados, buscando...</p>
                }
            </div>
        </div>
    )
}
