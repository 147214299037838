import { ContainerSec } from '@components/common/sectionspage/ContainerSec'
import { SectionsPage } from '@components/common/sectionspage/SectionsPage'
import { SellsCheck } from '@components/sells/page/SellsCheck'
import { TableSells } from '@components/sells/page/TableSells'
import { TableSellsOnline } from '@components/sells/page/TableSellsOnline'
import { SellsCounter } from '@models/sells/sellsCounter'
import { selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import React from 'react'

const CounterSellsPage = () => {
  const counter = useAppSelector(selectSellsCounter) as SellsCounter;
  return (
    <div>
      {
        counter && !counter.completed ? 
        <SellsCheck />
        :
        <SectionsPage indexSec={4} content={
          <>
              <ContainerSec isFirst={true} content={<TableSells />} id={"sells"} />
              <ContainerSec content={<TableSellsOnline />} id={"sellsOnline"} />
          </>
      } />
      }
    </div>
  )
}

export default CounterSellsPage