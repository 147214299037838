import { addSubstrings } from "./addSubstrings";

export const genArrSearchCenter = (displayName: string) => {
    let resultArray: string[] = [
      displayName.trim()
    ];
    const displaySliced = addSubstrings(displayName.trim());
    resultArray = resultArray.concat(displaySliced);
    const displayNameArray = displayName.split(' ');
    for (let i = 0; i < displayNameArray.length; i++) {
      const s = addSubstrings(displayNameArray[i]);
      resultArray = [...resultArray, ...s]
    }
    resultArray = resultArray.map((v) => v.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
    return resultArray;
}