import { db } from "@lib/firebase"
import { Centers } from "@models/centers/center";
import { doc, getDoc } from "firebase/firestore"
import * as Sentry from '@sentry/react';

export const getCenter = async (centerId: string, isAuth: boolean) => {
    try {
        const ref = doc(db, `centers/${centerId}`);
        const center = (await getDoc(ref)).data() as Centers;
        if (center) {
            return center
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}