import { toAbsoluteUrl } from '_metronic/helpers'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const SuperLayout = () => {
  useEffect(() => {
    const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat']
    BODY_CLASSES.forEach((c) => document.body.classList.add(c))
    document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/auth/bg7-dark.jpg')})`
    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c))
      document.body.style.backgroundImage = 'none'
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush  w-lg-650px py-5' style={{minHeight: 350}}>
            <div className='card-body py-10 d-flex flex-column justify-content-around' >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
