/* 
    TODO: add regex to set andorra apart
*/

export const checkPhoneAndEm = (phone: string, fakeEmail: boolean, prefixPhone: string[]) => {
    if (prefixPhone.length === 0) {
            const r = new RegExp(/^34?(6\d{2}|7[1-9]\d{1})\d{6}$/)
            return r.test(phone) || fakeEmail === false
    } else {
        const prefixMatch = prefixPhone.some(prefix => phone.startsWith(prefix));
        return prefixMatch || fakeEmail === false;
    }
}