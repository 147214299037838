import { ResCol, } from '@components/ui/container/ResCol'
import { Consent } from '@models/user/consent'
import { KTCard } from '_metronic/helpers'
import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import FormField from '@components/formik/FormField'
import SignatureCanvas from 'react-signature-canvas'
import { ActionButton } from '@components/ui/button/ActionButton'
import { ResRowToCol } from '@assets/sass/bootstrapEx'
import { BoldText } from '@components/ui/text/BoldText'
import { dataUrlBlob } from '@lib/helpers/consent/dataUrlBlob'
import { SubmitBtn } from '@components/formik/SubmitBtn'
import { useParams } from 'react-router-dom'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { db, storage } from '@lib/firebase'
import toast from 'react-hot-toast'
import { callCloud } from '@lib/helpers/callCloud'
import { useAuth } from 'app/modules/auth'
import { saveAs } from 'file-saver';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { useAppDispatch } from '@redux/store/hooks'
import { showModal } from '@redux/features/uiSlice/uiSlice'
import * as Sentry from '@sentry/react';
import { DelOp } from '@components/common/del/DelOp'
import { isTouchScreen } from '@lib/helpers/ui/isTouchScreen'

const initialValues = { dni: '', name: '' }
const schema = Yup.object().shape({
    dni: Yup.string().required('Es necesario un dni'),
    name: Yup.string().required('Es necesario un nombre')
})
interface Props {
    consent: Consent,
    consCompleted: () => void;
    delConsent: () => void;
}
/* 
    // https://github.com/eligrey/FileSaver.js/issues/735
    // https://tonynguyenit.medium.com/testing-and-debugging-your-react-app-on-mobile-ios-device-2734bf0952b2
*/
export const ConsentSel = ({ consent, consCompleted, delConsent }: Props) => {
    const dispatch = useAppDispatch();
    const drawRef = useRef<any>(null)
    const { id } = useParams();
    const [loading, setloading] = useState<boolean>(false)
    const { centerId } = useAuth()
    const readConsent = () => {
        dispatch(showModal({ view: 'READ_CONSENT', editId: consent.body }))
    }
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            setloading(true)
            try {
                const png = drawRef.current.toDataURL()
                const blobed = await dataUrlBlob(png)
                const name = `${id}-${consent.retrieve}`
                const refFile = ref(storage, `consents/${name}`)
                await uploadBytes(refFile, blobed)
                const url = await getDownloadURL(refFile)
                const ob = {
                    title: consent.title,
                    body: consent.body,
                    img: url,
                    dni: values.dni,
                    name: values.name,
                    date: new Date().toLocaleString('es-Es'),
                    logo: '',
                    centerName: ''
                }
                const sendConsent = callCloud('sendConsent')
                const res = (await sendConsent({ ob: ob, centerId: centerId, uid: id })).data as any;
                let i;
                const result: any[] = [];
                for (i in res) {
                    const d = res[i];
                    result.push(d)
                }
                const buffer = new Uint8Array(result)
                const blob = new Blob([buffer], { type: 'application/pdf' })
                const signedName = `${values.name}-${consent.title}-${id}`;
                if (!isTouchScreen()) {
                    saveAs(blob, `${signedName}.pdf`)
                }
                const refConsent = ref(storage, `ConsentPdf/${signedName}`)
                await uploadBytes(refConsent, blob)
                await updateDoc(doc(db, `users/${id}`), {
                    signedConsents: arrayUnion(signedName)
                })
                toast.success('Consentimiento enviado en caso de que el usuario tenga un email real')
                toast.success('Puedes consultar siempre que quieras los consentimientos firmados')
            } catch (error) {
                toast.error('Error')
                Sentry.configureScope(scope => scope.setTransactionName('Error at signing consent'))
                Sentry.captureException(error, { user: { id: id } });
            }
            setloading(false)
            setValues({ ...initialValues })
            resetForm()
            cleanDraw()
            consCompleted()
            toast.success('Consentimiento firmado')
        },
    })
    const cleanDraw = () => {
        if (drawRef) {
            drawRef.current.clear()
        }
    }

    return (
        <KTCard className='p-4 mt-10'>
            <Form formikVal={formik} >
                <div className='d-flex justify-content-between'>
                    <BoldText text={consent.title} />
                    <ActionButton text='Volver a seleccionar' action={consCompleted} />
                </div>
                <div className={`${ResRowToCol}`}>
                    <div className=' w-lg-75 w-100 '>
                        <ResCol
                            childrenFirst={
                                <FormField name='name' label='Nombre real del usuario' type='text' />
                            }
                            childrenSecond={
                                <FormField name='dni' label='Dni del usuario' type='text' />
                            }
                        />

                        <ActionButton text={'Leer consen.'} classNames='d-block mx-auto' action={readConsent} />

                        <div className='mt-10 d-flex flex-column flex-lg-row justify-content-between'>
                            <div className=' h-100  d-block mt-auto '>
                                <div className='mb-5 d-flex justify-content-center mt-4'>
                                    <ActionButton text={'Limpiar firma'} action={cleanDraw} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <SignatureCanvas penColor='#0c0d0d' ref={drawRef}
                                        canvasProps={{ width: 320, height: 144, className: 'sigCanvas' }} />
                                </div>
                            </div>
                            <div className='d-flex align-items-lg-end justify-content-center mt-5'>
                                <SubmitBtn loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-3'>
                    <DelOp del={delConsent} quest={'¿Quieres eliminar el consentimiento?'} text={'Eliminar'} />
                </div>
            </Form>
        </KTCard>
    )
}
