import { Form } from '@components/formik/Form';
import FormField from '@components/formik/FormField';
import { SubmitBtn } from '@components/formik/SubmitBtn';
import { db } from '@lib/firebase';
import { callCloud } from '@lib/helpers/callCloud';
import { convertPhone } from '@lib/helpers/remembers/convertPhone';
import { selectUser } from '@redux/features/clientSlice/clientSlice';
import { selectCenter } from '@redux/features/gquerySlice/gquerySlice';
import { setCloseModal } from '@redux/features/uiSlice/uiSlice';
import { useAppDispatch, useAppSelector } from '@redux/store/hooks';
import { doc, updateDoc } from 'firebase/firestore';
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import * as Yup from 'yup'
import * as Sentry from '@sentry/react';
import { useAuth } from 'app/modules/auth';
import { genArrSearchUser } from '@lib/helpers/user/genArrSearchUser';
import { BoldText } from '@components/ui/text/BoldText';
/* 
    TODO: 307 signal international center and code
*/

const initialValues = {
    phone: ''
}
const schema = Yup.object().shape({
    phone: Yup.string().required('Es necesario un teléfono')
})
const accFakeNumber = '000000000'
export const ChangePhone = () => {
    const [loading, setloading] = useState<boolean>(false);
    const user = useAppSelector(selectUser);
    const center = useAppSelector(selectCenter)
    const { currentUser } = useAuth()
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values, { setValues, resetForm }) => {
            if (user && center) {
                setloading(true)
                const { phone } = values
                const dataSearch = { displayName: user.displayName, phone: phone, email: user.email };
                const newSearchArr = genArrSearchUser(dataSearch);
                const caseAccepted = phone === accFakeNumber
                const converted = convertPhone(phone, center.prefixPhone);
                if (converted === '') {
                    if (caseAccepted) {
                        await updateDoc(doc(db, `users/${user?.uid}`), {
                            phone: phone,
                            search: newSearchArr
                        })
                        const delRemFakePhone = callCloud('delRemFakePhone');
                        await delRemFakePhone({ casePhone: phone, uid: user?.uid })
                        if (user?.email.includes('provisional') === false) {
                            Sentry.captureMessage(`Phone 0x9 change by ${currentUser?.email} role: ${currentUser?.role[0]}, in ${user.uid} `)
                        }
                    } else {
                        setloading(false)
                        setValues({ ...initialValues })
                        resetForm()
                        return toast.error(`El teléfono no puede recibir recordatorios, el único caso aceptado como falso es: ${accFakeNumber}`);
                    }
                } else {
                    const changePhoneRew = callCloud('changePhoneRew');
                    const rewDone: boolean = center?.wh === false ? true : (await changePhoneRew({ phoneBook: phone, phoneRem: converted, uid: user?.uid })).data as any;
                    if (rewDone) {
                        await updateDoc(doc(db, `users/${user?.uid}`), {
                            phone: phone,
                            search: newSearchArr
                        })
                    } else {
                        setloading(false)
                        setValues({ ...initialValues })
                        resetForm()
                        return toast.error('No ha sido posible reescribir todos los recordatorios');
                    }

                }
                setloading(false)
                toast.success('Cambiado')
                dispatch(setCloseModal())
            } else {
                toast.error('Error')
            }
        },
    })
    return (
        <Form formikVal={formik}>
            {
                center && center.prefixPhone.length > 0 ?
                    <>
                        <BoldText classNames='text-success' text={`Prefijos disponibles: ${center.prefixPhone.join(', ')}`} />
                        <BoldText classNames='text-warning' text={`Se ha detectado prefijos de teléfono para recordatorios,
                                 si el usuari@ debe recibir recordatorios no olvides poner el prefijo primero, ejemplo: ${center.prefixPhone[0]}748925`} />
                    </>
                    : null

            }
            <FormField name={'phone'} label={'Cambiar teléfono (si tiene que ser falso 0x9)'} type={'text'} />
            <div className='d-flex justify-content-center mt-5'>
                <SubmitBtn loading={loading} text='Enviar' />
            </div>
        </Form>
    )
}
