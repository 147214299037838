import { ActionButton } from '@components/ui/button/ActionButton';
import { db } from '@lib/firebase';
import { useAuth } from 'app/modules/auth';
import { endOfDay, endOfMonth, parse, startOfDay, startOfMonth } from 'date-fns';
import es from 'date-fns/locale/es';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from 'react-hot-toast';
import { useFormik } from 'formik'
import { MonthPicker } from '@components/formik/MonthPicker';
import { Form } from '@components/formik/Form';
import { Sell } from '@models/sells/sell';
type Props = {
  sendResBack: (arr: any[]) => void;
  nameCol: string,
}
const initialValues = { year: new Date().getFullYear(), month: ''}
export const FilterDate = React.memo(({ sendResBack, nameCol }: Props) => {
  const [needsMonth, setneedsMonth] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const { centerId } = useAuth()
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setValues }) => {
      const d = `${values.month} ${values.year}`;
      const parsedDate = parse(d, 'MMMM yyyy', new Date(), { locale: es });
      const firstDate = startOfMonth(parsedDate);
      const lastDate = endOfMonth(parsedDate);
      const q = query(collection(db, nameCol), where('counter', '>', 0), where('dateTime', '>=', firstDate), where('dateTime', '<=', lastDate), where('centerId', '==', centerId));
      const ds = (await getDocs(q)).docs.map((v) => {
        const doc = v.data();
        doc.dateTime = doc.dateTime.toDate();
        return doc
      }) as Sell[];
      if (ds.length === 0) {
        toast('Sin resultados')
      }
      sendResBack(ds.sort((a,b) => b.counter - a.counter));
      setValues({...initialValues})
    },
  })
  const {values, submitForm} = formik
  const handleChange = useCallback(async (d: Date | null) => {
    if (d) {
      setDate(d)
      const start = startOfDay(d)
      const end = endOfDay(d);
      const q = query(collection(db, nameCol), where('counter', '>', 0), where('dateTime', '>=', start), where('dateTime', '<=', end), where('centerId', '==', centerId));
      const ds = (await getDocs(q)).docs.map((v) => {
        const doc = v.data();
        doc.dateTime = doc.dateTime.toDate();
        return doc
      });
      if (ds.length === 0) {
        toast('Sin resultados')
      }
      sendResBack(ds.sort((a,b) => b.counter - a.counter));
    }
  }, [centerId, sendResBack, nameCol])
  const switchDate = () => {
    setneedsMonth(!needsMonth)
  }
  useEffect(() => {
    if (values.month !== '') {
      submitForm()
    }
  }, [values.month, values.year, submitForm])
  
  return (
    <>
      <div>
        <ActionButton text={needsMonth ? 'Escoger por día' : 'Escoger por mes'} action={switchDate} />
      </div>
      {
        needsMonth ?
          <Form formikVal={formik}>
            <MonthPicker />
          </Form>
          :
          <DatePicker
            selected={date}
            className='form-control form-control-solid w-250px'
            onChange={handleChange}
            locale={es}
            dateFormat='dd/MM/yyyy'
            portalId="root-portal"
            dayClassName={(currDate) => (currDate && (currDate.toDateString() === date?.toDateString()) ? 'selected_day_picker' : '')}
            placeholderText='Escoge una fecha'
          />
      }
    </>
  )
})
