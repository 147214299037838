import {Booking} from '@models/bookings/booking'
import {collection, doc, WriteBatch} from 'firebase/firestore'
import {doRandomUid} from '../random/doRandomUid'
import {db} from '@lib/firebase'
import {addHours} from 'date-fns'
const clone = require('clone-deep')
export const addDoubleBook = (
  /* 
    TODO: add workerUid 
  */
  argBook: Booking,
  addCanary: boolean,
  batch:  WriteBatch,
) => {
  const bookCol = collection(db, 'bookings')
  const refBook = doc(bookCol);
  const refSecondBook = doc(bookCol);
  const book = clone(argBook) as Booking;
  const secondBooking: Booking = clone(book)
  book.workerId = book.objDoubleWorker.workerIdA
  secondBooking.workerId = book.objDoubleWorker.workerIdB
  const randomId = doRandomUid(book.centerId)
  book.randomDoubleId = randomId
  secondBooking.randomDoubleId = randomId
  delete secondBooking.senderUid
  if (addCanary) {
    book.StartTime = addHours(book.StartTime, 1)
    book.EndTime = addHours(book.EndTime, 1)
    secondBooking.StartTime = addHours(secondBooking.StartTime, 1)
    secondBooking.EndTime = addHours(secondBooking.EndTime, 1)
  }
  book.idBook = refBook.id;
  secondBooking.idBook = refSecondBook.id;
  batch.set(refBook, book)
  batch.set(refSecondBook, secondBooking)
}
