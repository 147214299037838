import { selectCenter, selectSellsCounter } from '@redux/features/gquerySlice/gquerySlice'
import { useAppSelector } from '@redux/store/hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '@components/formik/Form'
import { JustEnd } from '@assets/sass/bootstrapEx'
import { InlineInput } from '@components/formik/InlineInput'
import { SubmitField } from '@components/formik/SubmitField'
import { TextArea } from '@components/formik/TextArea'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '@lib/firebase'
import { toast } from 'react-hot-toast'
import { Acc } from '@components/ui/accordion/Acc'
import { IconButton } from '@components/ui/button/IconButton'
import { useAuth } from 'app/modules/auth'
import { useState } from 'react'
import { callCloud } from '@lib/helpers/callCloud'
import * as Sentry from '@sentry/react';
import { genArrSearchCenter } from '@lib/helpers/search/genArrSearchCenter'
/* 
    if you ever need to change logo or canary, change it also in sellsCounter ref
*/
const schema = Yup.object().shape({
    displayName: Yup.string().required('No puede quedar en blanco'),
    mailCenter: Yup.string().required('No puede quedar en blanco'),
    placeCenter: Yup.string().required('No puede quedar en blanco'),
    centerPhone: Yup.string().required('No puede quedar en blanco'),
    centerDesc: Yup.string().required('No puede quedar en blanco'),
    facebook: Yup.string().required('No puede quedar en blanco'),
    instagram: Yup.string().required('No puede quedar en blanco'),
})

export const EditCenter = () => {
    const [loaderUrl, setloaderUrl] = useState(false)
    const counter = useAppSelector(selectSellsCounter);
    const center = useAppSelector(selectCenter)
    const { currentUser } = useAuth()
    const formik = useFormik({
        initialValues: {
            displayName: center!.displayName,
            mailCenter: center!.mailCenter,
            placeCenter: center!.placeCenter,
            timeline: center!.timeline,
            centerPhone: center!.centerPhone,
            centerDesc: center!.centerDesc,
            comUrl: (center!.comUrl as string),
            facebook: center!.facebook,
            instagram: center!.instagram
        },
        validationSchema: schema,
        onSubmit: async () => { }
    })
    const changeName = async () => {
        const f = formik.values;
        const searchArr = genArrSearchCenter(f.displayName);
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            displayName: f.displayName,
            searchString: searchArr
        })
        await updateDoc(doc(db, `sellsCounter/${counter?.docId}`), {
            displayName: f.displayName,
        })
        toast.success('Cambiado')
    }
    const changemailCenter = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            mailCenter: f.mailCenter
        })
        toast.success('Cambiado')
    }
    const changeplaceCenter = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            placeCenter: f.placeCenter
        })
        toast.success('Cambiado')
    }
    const changecenterPhone = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            centerPhone: f.centerPhone
        })
        toast.success('Cambiado')
    }
    const changecenterDesc = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            centerDesc: f.centerDesc
        })
        toast.success('Cambiado')
    }
    const chfacebook = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            facebook: f.facebook
        })
        toast.success('Cambiado')
    }
    const chinstagram = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            instagram: f.instagram
        })
        toast.success('Cambiado')
    }
    const chweb = async () => {
        setloaderUrl(true)
        try {
            const f = formik.values;
            const comUrlChange = callCloud('comUrlChange')
            await comUrlChange({ newUrl: f.comUrl, oldUrl: center?.comUrl })
            await updateDoc(doc(db, `centers/${center?.centerName}`), {
                comUrl: f.comUrl
            })
            toast.success('Cambiado')
        } catch (error) {
            Sentry.configureScope(scope => scope.setTransactionName('comUrlChange'))
            Sentry.captureException(error);
        }
        setloaderUrl(false)
    }
    const copyToClipBoard = async () => {
        try {

            await navigator.clipboard.writeText(`${"https://micentro.estetical.es"}/centro/${center?.comUrl!}`);
            toast.success('Copiado')
        } catch (err) {
            toast.error('Error al copiar')

        }
    };
    const chtimeline = async () => {
        const f = formik.values;
        await updateDoc(doc(db, `centers/${center?.centerName}`), {
            timeline: f.timeline
        })
        toast.success('Cambiado')
    }
    return (
        <div className='p-4'>
            <Form formikVal={formik}>
                <Acc id='Micentro' title='Enlace web' classNames='mb-5'>
                    <div className='d-flex justify-content-center align-items-center is-pointer mb-5' onClick={() => copyToClipBoard()}>
                        <IconButton iconClass={'fa-regular fa-copy'} />
                        <p className='mb-0 text-decoration-underline'>micentro.estetical.es/centro/{center?.comUrl}</p>
                    </div>
                    <div>
                        <InlineInput name={'comUrl'} label={'Url de la página web'} />
                        <div className={`${JustEnd} mb-5`}>
                            <SubmitField text='Cambiar' name={'comUrl'} loading={loaderUrl} handleSubmit={chweb} />
                        </div>
                    </div>
                </Acc>
                <Acc title={`Datos de contacto ${center?.displayName}`} id={'Contact'} classNames='mb-5'>
                    <div>
                        <InlineInput name={'displayName'} label={'Cambia el nombre del centro'} />
                    </div>

                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'displayName'} loading={false} handleSubmit={changeName} />
                    </div>
                    <InlineInput name={'mailCenter'} label={'Cambia el email'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'mailCenter'} loading={false} handleSubmit={changemailCenter} />
                    </div>
                    <InlineInput name={'centerPhone'} label={'Cambia el teléfono'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'centerPhone'} loading={false} handleSubmit={changecenterPhone} />
                    </div>
                </Acc>

                <Acc classNames='mb-5' title={'Info del centro'} id={'InfoCenter'}>

                    <InlineInput name={'placeCenter'} label={'Cambia la dirección'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'placeCenter'} loading={false} handleSubmit={changeplaceCenter} />
                    </div>

                    <InlineInput name={'timeline'} label={'Cambia el horario del centro (formato de ejemplo: Lunes – Viernes: 10.00 a 20.00, Sábado: 10.00 a 15.00, Domingo: Cerrado)'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'timeline'} loading={false} handleSubmit={chtimeline} />
                    </div>

                    <div className='mb-4'>
                        <TextArea name={'centerDesc'} rows={4} label='Descripción del centro' />
                        <div className={`${JustEnd} mb-5 mt-5`}>
                            <SubmitField text='Cambiar' name={'centerDesc'} loading={false} handleSubmit={changecenterDesc} />
                        </div>
                    </div>
                </Acc>

                <Acc title={'Redes sociales'} id={'Social'} >

                    <InlineInput name={'facebook'} label={'Url de Facebook'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'facebook'} loading={false} handleSubmit={chfacebook} />
                    </div>

                    <InlineInput name={'instagram'} label={'Url de Instagram'} />
                    <div className={`${JustEnd} mb-5`}>
                        <SubmitField text='Cambiar' name={'instagram'} loading={false} handleSubmit={chinstagram} />
                    </div>
                </Acc>

            </Form>
        </div>
    )
}
