import TableCnt from '@components/ui/table/OptTable/TableCnt';
import { KTCard } from '_metronic/helpers';
import { useAuth } from 'app/modules/auth';
import { where } from 'firebase/firestore';
import React from 'react'
const heads = ['Número', 'Total', 'Cliente', 'Fecha', 'Visualizar', 'Acciones']

export const TableSellsOnline = () => {
    const { centerId } = useAuth();
    const q = [where('counter', '>', 0), where('centerId', '==', centerId), where('paymentIntentId', '!=', '')]
    return (
        <KTCard>
            <TableCnt filterDate={true} needsSearch={false} nameCol={'sells'} queryCons={q} nameOrder={'dateTime'} desc={true} heads={heads} />
        </KTCard>
    )
}
