import useBill from '@lib/hooks/bill/useBill'
import { selectBill, selectTitle, selectType } from '@redux/features/billSlice/billSlice'
import { useAppSelector } from '@redux/store/hooks'
import { InfoBillCard } from './InfoBillCard'
import { ItemBill } from './ItemBill'
import { crossIcon, efIcon, onlineIcon, othersIcon, tarIcon } from '@assets/sass/bootstrapEx'
import { useBillContext } from '@lib/context/bill/BillCxt'

export const ShowTotalBill = () => {
  const currType = useAppSelector(selectType);
  const title = useAppSelector(selectTitle);
  const t = currType !== null ? currType : null;
  const { totsCol } = useBill(t)
  return (
    <InfoBillCard color={'info'} children={
        <>
            <ItemBill icon={tarIcon} title={'Tarjeta'} total={`${totsCol.tar.toFixed(2)}`} />
            <ItemBill icon={efIcon} title={'Efectivo'} total={`${totsCol.ef.toFixed(2)}`} />
            <ItemBill icon={othersIcon} title={'Otros'} total={`${totsCol.others.toFixed(2)}`} />
            {
              t !== null ?
              <ItemBill icon={crossIcon} title={'Mixto'} total={`${totsCol.mix.toFixed(2)}`} />
              : 
              null

            }
            {
              t === null ?
              <ItemBill icon={onlineIcon} title={'Online'} total={`${totsCol.online.toFixed(2)}`} />
              :
              null
            }
        </>
    } title={title} text={''} total={`${totsCol.total.toFixed(2)} €`} />
  )
}
